import { useEffect, useState } from 'react'
import './tic_tac_toe.css'
import confetti from 'canvas-confetti'
import {Square} from './Square'
import { TURNS, WINNER_COMBOS } from './constans'
import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';



function TicTacToe() {



  const [board, setBoard] = useState(() => {
    console.log("Inicializando el estado del board")
    const boardFromStorage = window.localStorage.getItem('board')
    return boardFromStorage ? JSON.parse(boardFromStorage) : Array(9).fill(null)
  })

  const [turn, setTurn] = useState(() => {
    console.log("Inicializando el estado del turno")
    const turnFromStorage = window.localStorage.getItem('turn')
    return turnFromStorage ? turnFromStorage : TURNS.X
  })
  const [winner, setWinner] = useState(null)
  
  const checkWinner = (boardToCheck) => {
    for(const combo of WINNER_COMBOS) {
      const [a, b, c] = combo
      if (boardToCheck[a] && boardToCheck[a] === boardToCheck[b] && boardToCheck[a] === boardToCheck[c]) {
        return boardToCheck[a]
      }
    }
    return null
  }

  const resetGame = () => {
    setBoard(Array(9).fill(null))
    setTurn(TURNS.X)
    setWinner(null)
    window.localStorage.removeItem('board')
    window.localStorage.removeItem('turn')
  }

  const checkEndGame = (newBoard) => {
    return newBoard.every((square) => square !== null)
  }


  const updateBoard = (index) => {
    //si ya hay algo en ese index, no hagas nada o si hay un ganador, no hagas nada
    if (board[index] || winner) return
    const newBoard = [...board]
    newBoard[index] = turn
    setBoard(newBoard)
    const newTurn = turn === TURNS.X ? TURNS.O : TURNS.X
    setTurn(newTurn)
    //guardar partida
    window.localStorage.setItem('board', JSON.stringify(newBoard))
    window.localStorage.setItem('turn', newTurn)
    const newWinner = checkWinner(newBoard)
    if (newWinner) {
      confetti()
      setWinner(newWinner)
    } else if (checkEndGame(newBoard)) {
      setWinner(null)
    }
  }
  useEffect(() => {
    console.log("useEffect")
  }, [winner])

  return (
    <div className='tictacbody'>
    <main className='board'>
     <button>
      <Link to="/#projects">Home</Link>
    </button>
      <h1>Tic tac toe</h1>
      <button onClick={resetGame}>Empezar de nuevo</button>
      <section className='game'>
        {
          board.map((_, index) => {
            return (
              <Square
                key={index}
                index={index}
                updateBoard={updateBoard}
                >
                {board[index]}
                </Square>

            )
                
        }
        )}
      </section>
      <section className='turn'>
        <Square isSelected={turn === TURNS.X}>{TURNS.X}</Square>
        <Square isSelected={turn === TURNS.O}>{TURNS.O}</Square>
      </section>
  
        {
          winner !== null && (
            <section className='winner'>
              <div className="text">
                <h2>
                  {
                    winner === false ? 'Empate' : `El ganador es ${winner}`
                  }
                </h2>
                <header className='win'> 
                {winner && <Square>{winner}</Square>}
                </header>
                <footer>
                  <button onClick={resetGame}>Empezar de nuevo</button>
                </footer>
              </div>
            </section>
          )
        }
      
    </main>
    </div>
)
}

export default TicTacToe