/* eslint-disable jsx-a11y/anchor-has-content */
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { FlagIcon } from 'react-flag-kit';
import { FaReact, FaHtml5, FaJs, FaDatabase, FaEnvelope, FaLinkedin, FaAngular, FaAws, FaPython } from 'react-icons/fa';
import awsCloudPractitioner from '../assets/aws-cloud-practitioner.png';
import awsDeveloperAssociate from '../assets/aws-developer-associate.png';
import awsDevOpsEngineer from '../assets/aws-devops-engineer.png';
import fondo from '../assets/fondo.jpg'; // Importa la imagen de fondo
import logoImg from '../assets/JDM.gif'; // Importa la imagen del logo
import tictactoe from '../assets/tictactoe.png'; // Importa la imagen del proyecto
import mousefollower from '../assets/mousefollower.png'; // Importa la imagen del proyecto
import me from '../assets/me.jpg'; // Importa la imagen del proyecto
import pokedex from '../assets/pokedex.png'; // Importa la imagen del proyecto
import './Home.css';
// import { useNavigate } from 'react-router-dom';
import { FaRegEye } from 'react-icons/fa'; // Importa el icono
import { FaArrowUp } from 'react-icons/fa'; // Importa el icono


function Home() {
  const { t, i18n } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="home-container">
      <header className="header" style={{ backgroundImage: `url(${fondo})` }}>
        <div className="header-overlay">
          <div className="language-buttons">
            <button className="language-button" onClick={() => changeLanguage('en')}>
              <FlagIcon code="GB" size={20} />
            </button>
            <button className="language-button" onClick={() => changeLanguage('es')}>
              <FlagIcon code="ES" size={20} />
            </button>
          </div>
          <img src={logoImg} alt="Logo" className="logo" />
          <nav className="nav">
            <a href="#about-me" className="nav-link">{t('HEADER.ABOUT')}</a>
            <a href="#projects" className="nav-link">{t('HEADER.PROJECTS')}</a>
            <a href="#skills" className="nav-link">{t('HEADER.SKILLS')}</a>
            <a href="#certifications" className="nav-link">{t('HEADER.CERTIFICATIONS')}</a>
            <a href="#contact" className="nav-link">{t('HEADER.CONTACT')}</a>
          </nav>
          <h1 className="title">{t('HEADER.TITLE')}</h1>
          <p className="subtitle">{t('HEADER.SUBTITLE')}</p>
          
        </div>
      </header>

      <section id="about-me" className="section about-me">
        <img src={me} alt="Profile" className="profile-image" />
        <h2 className="section-title">{t('ABOUT_ME.TITLE')}</h2>
        <p className="paragraph">{t('ABOUT_ME.DESCRIPTION_1')}</p>
        <p className="paragraph">{t('ABOUT_ME.DESCRIPTION_2')}</p>
        <p className="paragraph">{t('ABOUT_ME.DESCRIPTION_3')}</p>
        <p className="paragraph">{t('ABOUT_ME.DESCRIPTION_4')}</p>
        <p className="paragraph">{t('ABOUT_ME.DESCRIPTION_5')}</p>
        <p className="paragraph">{t('ABOUT_ME.DESCRIPTION_6')}</p>
      </section>

      <section id="projects" className="section featured-projects">
      <h2 className="section-title">{t('PROJECTS.TITLE')}</h2>
      <div className="project-list">
        <div className="project-item">
          <h3 className="project-title">Tic Tac Toe</h3>
          <img src={tictactoe} alt="Tic Tac Toe" className="project-image" />
          <p className="project-description">{t('PROJECTS.PROJECT_1')}</p>
          <a href="tictactoe" className="project-link">
            <FaRegEye className="project-icon" />
          </a> 
        </div>
        <div className="project-item">
          <h3 className="project-title">Mouse Follower</h3>
          <img src={mousefollower} alt="Tic Tac Toe" className="project-image" />
          <p className="project-description">{t('PROJECTS.PROJECT_2')}</p>
          <a href="mousefollower" className="project-link">
            <FaRegEye className="project-icon" />
          </a>
        </div>
        <div className="project-item">
          <h3 className="project-title">Pokedex</h3>
          <img src={pokedex} alt="Tic Tac Toe" className="project-image" />
          <p className="project-description">{t('PROJECTS.PROJECT_3')}</p>
          <a href="https://pokedex.jdmdeveloper.dev" target="_blank" rel="noopener noreferrer"  className="project-link">
            <FaRegEye className="project-icon" /> 
          </a>
        </div>
      </div>
    </section>

      <section id="skills" className="section skills">
        <h2 className="section-title">{t('SKILLS.TITLE')}</h2>
        <div className="skills-list">
          <div className="skill-item">
            <FaReact className="skill-icon" />
            <p className="skill-name">React</p>
            <div className="skill-description">{t('SKILLS.REACT')}</div>
          </div>
          <div className="skill-item">
            <FaAngular className="skill-icon" />
            <p className="skill-name">Angular</p>
            <div className="skill-description">{t('SKILLS.ANGULAR')}</div>
          </div>
          <div className="skill-item">
            <FaPython className="skill-icon" />
            <p className="skill-name">Python</p>
            <div className="skill-description">{t('SKILLS.PYTHON')}</div>
          </div>
          <div className="skill-item">
            <FaJs className="skill-icon" />
            <p className="skill-name">JavaScript</p>
            <div className="skill-description">{t('SKILLS.JAVASCRIPT')}</div>
          </div>
          <div className="skill-item">
            <FaHtml5 className="skill-icon" />
            <p className="skill-name">HTML5</p>
            <div className="skill-description">{t('SKILLS.HTML5')}</div>
          </div>
          <div className="skill-item">
            <FaAws className="skill-icon" />
            <p className="skill-name">AWS</p>
            <div className="skill-description">{t('SKILLS.AWS')}</div>
          </div>
          <div className="skill-item">
            <FaDatabase className="skill-icon" />
            <p className="skill-name">SQL/NoSQL</p>
            <div className="skill-description">{t('SKILLS.SQL_NOSQL')}</div>
          </div>
        </div>
      </section>

      <section id="certifications" className="section certifications">
        <h2 className="section-title">{t('CERTIFICATIONS.TITLE')}</h2>
        <div className="certifications-list">
          <div className="certification-item">
            <img src={awsCloudPractitioner} alt="AWS Certified Cloud Practitioner" className="certification-icon" />
            <p className="certification-name">{t('CERTIFICATIONS.CERTIFICATION_1.NAME')}</p>
            <div className="certification-description">{t('CERTIFICATIONS.CERTIFICATION_1.DESCRIPTION')}</div>
          </div>
          <div className="certification-item">
            <img src={awsDeveloperAssociate} alt="AWS Certified Developer - Associate" className="certification-icon" />
            <p className="certification-name">{t('CERTIFICATIONS.CERTIFICATION_2.NAME')}</p>
            <div className="certification-description">{t('CERTIFICATIONS.CERTIFICATION_2.DESCRIPTION')}</div>
          </div>
          <div className="certification-item">
            <img src={awsDevOpsEngineer} alt="AWS Certified DevOps Engineer - Professional" className="certification-icon" />
            <p className="certification-name">{t('CERTIFICATIONS.CERTIFICATION_3.NAME')}</p>
            <div className="certification-description">{t('CERTIFICATIONS.CERTIFICATION_3.DESCRIPTION')}</div>
          </div>
        </div>
      </section>

      <section id="contact" className="section contact">
        <h2 className="section-title">{t('CONTACT.TITLE')}</h2>
        <p className="paragraph">{t('CONTACT.DESCRIPTION')}</p>
        <div className="contact-methods">
          <a href="mailto:info@jdmdeveloper.dev" className="contact-icon">
            <FaEnvelope size={40} />
          </a>
          <a href="https://linkedin.com/in/jdmdeveloper" className="contact-icon" target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={40} />
          </a>
        </div>
      </section>
      <div className="scroll-to-top">
      {isVisible && (
        <button onClick={scrollToTop} className="scroll-button">
          <FaArrowUp />
        </button>
      )}
    </div>
    </div>
    
  );
}

export default Home;
