import React from 'react';
import Home from './components/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TicTacToe from './components/projects/tictactoe/tic_tac_toe';
import MouseFollower from './components/projects/mouse-follower/mouse-follower';
import './App.css';

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/tictactoe" element={<TicTacToe />} />
      <Route path="/#projects" element={<Home />} />
      <Route path="/mousefollower" element={<MouseFollower />} />
    </Routes>
  </Router>
);

export default App;

