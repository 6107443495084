import './mouse-follower.css'
import { useEffect, useState } from 'react'
import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
function MouseFollower () {

const [enabled, setEnabled] = useState(false)
const [position, setPosition] = useState({x: 0, y: 0})

    useEffect(() => {
        console.log('efecto', {enabled})
        const handleMove = (event) => {
            const {clientX, clientY} = event
            setPosition({ x: clientX, y: clientY })

        }
        if (enabled) {
            window.addEventListener('pointermove', handleMove)
        } 
        return () => {
            window.removeEventListener('pointermove', handleMove)
        }
    },[enabled])
    
    return (

        <main className='boardmousefollower'>
        <button>
            <Link to="/#projects">Home</Link>
        </button>
            <div style={{
                position: 'absolute',
                backgroundColor: 'rgba(0,0,0,0.5)',
                border: '1px solid #fff',
                borderRadius: '50%',
                opacity: 0.8,
                pointerEvents: 'none',
                left: -20,
                top: -20,
                width: 40,
                height: 40,
                transform: `translate(${position.x}px, ${position.y}px)`,
            }}/>
            <h1>Mouse Follower</h1>
            <button onClick={() => setEnabled(!enabled)}>
                {enabled ? 'Desactivar' : 'Activar'} seguir puntero</button>
        
        </main>

    )
}


export default MouseFollower